import React, { Component } from "react";
import * as Data from '../../Data/home.json';

class Thankyou extends Component {

    render(){
        return <div>
            <div class="content">
                <div class="wrapper-1">
                    <div class="wrapper-2">
                    <h1>Thank you !</h1>
                    <p>Thanks for Contacting Us.<br /> Our Team will get back to you soon.  </p>
                    <a  href='/home'> <button class="go-home"> go home</button></a>
                    </div>
                </div>
            </div>
            <br />
        </div>
    }
}

export default Thankyou;
