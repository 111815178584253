import React, { Component } from "react";
import jobsbanner from '../../../assets/images/jobsbanner.jpg';
import blogdetails from '../../../assets/images/blogdetails.jpg';
import blogdetails2 from '../../../assets/images/digitals.jpg';
import { Container, Button, Row, Col } from 'react-bootstrap';
import * as blogs from '../../../Data/blogdetails';


class Digital extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {

    return <div className="Inner-page">
      <div className="inner-page-banner" style={{ backgroundImage: `url(${jobsbanner})` }}>

        <Container>
          <div className="banner-text">
            <div data-aos="fade-up"
              data-aos-duration="2000" >
              <h5>What we do</h5>
              <h1>Digital</h1>
            </div>
          </div>
        </Container>

      </div>
      <div className="staffing-section p-80">
        <div className="container">
          <Row>
            <Col xs={12} md={12} lg={12} data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine">

              <img src={blogdetails2} alt="banner" />

            </Col>
            <Col xs={12} md={12} lg={12} data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine">

              <div className="staffingpstyle tickli">
                <div className="title-text afternone">

                  <h2>Digital</h2>
                </div>
                <p>Digital Industry is an innovative and technology leader that encourages businesses towards digital transformation. It is a known fact that digital technology contributes to significant changes in our daily lives. The global digital market will see some rapid changes taking place and is expected to see a growth of around 16.5% between current times and 2025.  </p>
                <p>New advancements and developments indicate the creation of new opportunities for different professionals in the digital industry. The Internet has played a vital role in the digitalization process. As new opportunities are created, we cater to the staffing needs of our clients.</p>
                <ul>
                  <li>	As the digital industry is broad and diversified, we provide staffing solutions to our clients for different types of roles.</li>
                  <li>		Our specialized team understands and adapts to the changing technologies to help you better in finding the right workforce for your organization.</li>
                  <li>	We provide the right talent with our best-in-class techniques with unparalleled collaboration. </li>
                  <li>	We source candidates on a temporary and permanent basis to fulfil your specific team’s requirements. </li>
                </ul>
              </div>
            </Col>
          </Row>

        </div>
      </div>
    </div>;
  }
}

export default Digital;
