import React, { Component } from "react";
import jobsbanner from '../../assets/images/jobsbanner.jpg';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";

import * as Data from '../../Data/jobs.json';
import JobHeader from "./JobHeader";

class Jobs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selected: '',
      data: Data.data
    }
  }

  onSelect = (key) => {
    let filteredValues = Data.data.map((d, i) => {
      let index = d.keys.indexOf(key);
      if (index != -1) {
        return d;
      };
      return null;
    });

    let filterd = filteredValues.filter(function (el) {
      return el != null;
    })
    this.setState({
      selected: key,
      data: filterd
    });
  }


  render() {
    return <div className="Inner-page">
      <div className="inner-page-banner" style={{ backgroundImage: `url(${jobsbanner})` }}>

        <Container>
          <div className="banner-text">
           <div data-aos="fade-up"
     data-aos-duration="2000" >
            <h5>Work With Us</h5>
            <h1>Careers</h1>
            <p>Begin your career with Equitas IT INC. Join our dynamic team and build the future of your dreams. </p>
          </div>
  </div>
        </Container>

      </div>
      <div className="jobs-loop-section">
        <Container>
          <div className="jobsmain" data-aos="fade-up"
     data-aos-duration="2000">
            {/* {
              Data.options.map((d, i) => {
                return <div onClick={() => this.onSelect(d.title)} className="jobs-box">
                  <div className="jobs-box-inner">
                    <FontAwesomeIcon  />
                    <p>Information<br/>
                      Technology</p>
                  </div>
                </div>
              })
            } */}
        
            <JobHeader selected={this.state.selected} title='Information Technology' onClick={(title) => this.onSelect(title)}>
            <img src="/images/8.png" alt="banner" />
              <p>Information<br />
                Technology</p>
            </JobHeader>

            <JobHeader selected={this.state.selected} title='Pharmaceutical & Healthcare' onClick={(title) => this.onSelect(title)}>
            <img src="/images/9.png" alt="banner" />
              <p>Pharmaceutical
                <br />
                & Healthcare</p>
            </JobHeader>

            <JobHeader selected={this.state.selected} title='Automobile' onClick={(title) => this.onSelect(title)} >
            <img src="/images/10.png" alt="banner" />
              <p>Automobile</p>
            </JobHeader>

            <JobHeader selected={this.state.selected} title='Private Equity' onClick={(title) => this.onSelect(title)} >
              <img src="/images/2.png" alt="banner" />
              <p>Private Equity</p>
            </JobHeader>

            <JobHeader selected={this.state.selected} title='Engineering' onClick={(title) => this.onSelect(title)} >
            <img src="/images/1.png" alt="banner" />
              <p>Engineering</p>
            </JobHeader>

            <JobHeader selected={this.state.selected} title='Banking & Financial Services' onClick={(title) => this.onSelect(title)} >
            <img src="/images/1.png" alt="banner" />
              <p>Banking &
                Financial <br />
                Services</p>
            </JobHeader>

            <JobHeader selected={this.state.selected} title='Infrastructure' onClick={(title) => this.onSelect(title)} >
            <img src="/images/8.png" alt="banner" />
            <p>Infrastructure</p>
            </JobHeader>

            <JobHeader selected={this.state.selected} title='Internet Ecommerce' onClick={(title) => this.onSelect(title)} >
            <img src="/images/3.png" alt="banner" />
               <p>Internet/<br />
                Ecommerce</p>
            </JobHeader>

            <JobHeader selected={this.state.selected} title='Digital' onClick={(title) => this.onSelect(title)} >
            <img src="/images/10.png" alt="banner" />
             <p>Digital</p>
            </JobHeader>

            <JobHeader selected={this.state.selected} title='Research & Consulting' onClick={(title) => this.onSelect(title)} >
            <img src="/images/8.png" alt="banner" />
            <p>Research<br />
                & Consulting</p>
            </JobHeader>
          </div>
        </Container>
      </div>

      <div className="jobspost-loop-section">
        <Container>
          {<main>
              <div class="landing-page"   style={{ backgroundImage: `url(${jobsbanner})` }}>
                <div class="landing-text-inner">
                <h1>Coming Soon</h1>
                <ul class="social-link">
                    <li class="facebook">
                        <a href="#" data-tippy="Facebook" data-tippy-inertia="true" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>  </a>
                    </li>
                    <li class="twitter">
                        <a href="#" data-tippy="Twitter" data-tippy-inertia="true" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>  </a>
                    </li>
                    <li class="instagram">
                        <a href="#" data-tippy="Instagram" data-tippy-inertia="true" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg> </a>
                    </li>
                </ul>
              </div>
              </div>
          </main>}
          
           <Row>
             {/*
              this.state.data.map((d, i) => {
                return <Col xs={12} md={6} data-aos="fade-up"
     data-aos-duration="2000">
                  <div className="jobpostbox">
                    <h2>{d.title}</h2>
                    <p>{d.description}</p>
                    <Link className="btn btn-light" to="/jobs/detail">Apply Now</Link>
                  </div>
                </Col>
              })<div className="viewallbtn"><Button variant="light">View All Jobs</Button> </div> 
           */ }
          </Row> 
          
        </Container>
      </div>


    </div >;
  }
}

export default Jobs;
