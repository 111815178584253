import React, { Component } from "react";
import jobsbanner from '../../../assets/images/jobsbanner.jpg';
import blogdetails from '../../../assets/images/blogdetails.jpg';
import blogdetails2 from '../../../assets/images/heltcare.jpg';
import { Container, Button, Row, Col } from 'react-bootstrap';
import * as blogs from '../../../Data/blogdetails';


class Pharmaceuticalhelthcare extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {

    return <div className="Inner-page">
      <div className="inner-page-banner" style={{ backgroundImage: `url(${jobsbanner})` }}>

        <Container>
          <div className="banner-text">
            <div data-aos="fade-up"
              data-aos-duration="2000" >
              <h5>What we do</h5>
              <h1>Pharmaceutical & Healthcare </h1>
            </div>
          </div>
        </Container>

      </div>
      <div className="staffing-section p-80">
        <div className="container">
          <Row>
            <Col xs={12} md={12} lg={12} data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine">

              <img src={blogdetails2} alt="banner" />

            </Col>
            <Col xs={12} md={12} lg={12} data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine">

              <div className="staffingpstyle tickli">
                <div className="title-text afternone">

                  <h2>Pharmaceutical & Healthcare </h2>
                </div>


                <p>The US Healthcare & Pharmaceutical industry is a highly globalized industry backed by multinational companies and has an enormous influence on global health and economic productivity. It holds over 45% of the global pharmaceutical market and comprises of world’s most renowned companies engaged in healthcare, research and development.</p>
                <p>With evolving technologies, there are increasing opportunities in the healthcare and pharmaceutical industry. Our recruiters with deep domain knowledge understand the requirements and source the right workforce to our clients.</p>
                <ul>
                  <li>	Our team of professionals work closely with clients to understand their needs.</li>
                  <li>	Our team is dedicated to deliver the highly qualified candidates to the organization right from entry level to experienced candidates.</li>
                  <li>	Robust process of evaluation allows our team of experts to bring out the right outcome.</li>
                  <li>	We have a dedicated recruitment division to cater to the needs of our client across various verticals in the healthcare and pharmaceutical industry.</li>
                </ul>
              </div>
            </Col>
          </Row>

        </div>
      </div>
    </div>;
  }
}

export default Pharmaceuticalhelthcare;
