import React, { Component } from "react";
import jobsbanner from '../../../assets/images/jobsbanner.jpg';
import blogdetails from '../../../assets/images/blogdetails.jpg';
import blogdetails2 from '../../../assets/images/automotive.jpg';
import { Container, Button, Row, Col } from 'react-bootstrap';
import * as blogs from '../../../Data/blogdetails';


class Automotive extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {

    return <div className="Inner-page">
      <div className="inner-page-banner" style={{ backgroundImage: `url(${jobsbanner})` }}>

        <Container>
          <div className="banner-text">
            <div data-aos="fade-up"
              data-aos-duration="2000" >
              <h5>What we do</h5>
              <h1>Automotive </h1>
            </div>
          </div>
        </Container>

      </div>
      <div className="staffing-section p-80">
        <div className="container">
          <Row>
            <Col xs={12} md={12} lg={12} data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine">

              <img src={blogdetails2} alt="banner" />

            </Col>
            <Col xs={12} md={12} lg={12} data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine">

              <div className="staffingpstyle tickli">
                <div className="title-text afternone">

                  <h2>Automotive </h2>
                </div>


                <p>The United States has one of the world’s largest automotive markets. To meet the rising need of the workforce, we work actively with our automotive clients to help them in finding the right talent for sustainable long-term growth. We are committed to deliver cost-effective staffing solutions to our clients.</p>
                <ul>
                  <li>	To meet the specific needs of our clients, our recruitment team has extensive experience in providing high-quality personnel across various verticals.</li>
                  <li>		Our recruitment process includes hiring on contract and permanent basis.</li>
                  <li>	We have ability to source highly qualified candidates through selective and targeted recruiting, database searching and networking.</li>
                  <li>	Our proficiency & industry knowledge enables us to provide you with market insights and trends.</li>
                </ul>
              </div>
            </Col>
          </Row>

        </div>
      </div>
    </div>;
  }
}

export default Automotive;
