import React, { Component } from "react";
import { Row, Container, Col } from 'react-bootstrap';
import footerlogo from '../../assets/images/footer-logo.png';
import map from '../../assets/images/map.jpg';
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return <div className="footer-main">
      <Container>
        <Row>
          <Col xs={12} md={1}></Col>
          <Col xs={12} md={4}>
            <div className="footer-logo-sec">
              <img src={footerlogo} alt="Logo" />
            </div>
            <p>Equitas IT INC combines deep technology, staffing knowledge & long-term experience to create talent solutions to increase teams’ efficiency & agility, resulting in faster time-to-market and operational cost savings.</p>
          </Col>
          <Col xs={12} md={1}></Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={12} md={5}>
                <h2>Quick Links</h2>
                <ul class="widget-list-item text-hawkes-blue">
                  <li>
                    <a href="/about-us">About us</a>
                  </li>
                  <li>
                    <a href="/jobs">Jobs</a>
                  </li>
                  <li>
                    <a href="/services">Our Services</a>
                  </li>
                  <li>
                    <a href="/blogs">Blogs</a>
                  </li>
                  <li>
                    <a href="/contact-us">Contact us</a>
                  </li>
                </ul>
              </Col>
              <Col xs={12} md={6}>
                <h2>Get in Touch</h2>
                <p className="footeraddress"> <FontAwesomeIcon icon={faMapMarkerAlt} /> 1630 W Prosper Trail suite 630, Prosper, TX 75078<br />
                  <a href="tel:469-629-3066"> <svg viewBox="0 0 24 24" width="15" height="15" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="16 2 16 8 22 8"></polyline><line x1="23" y1="1" x2="16" y2="8"></line><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg> (469) 629-3066</a><br />
                  <a href="mailto:admin@equitasitinc.com"> <svg viewBox="0 0 24 24" width="15" height="15" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="4"></circle><path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path></svg> admin@equitasitinc.com</a></p>

                <ul class="social-link">
                  <li class="facebook">
                    <a href="https://www.facebook.com/equitasitinc" target="_blank" data-tippy="Facebook" data-tippy-inertia="true" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder">
                      <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>  </a>
                  </li>
                  <li class="twitter">
                    <a href="https://twitter.com/equitasitinc" target="_blank" data-tippy="Twitter" data-tippy-inertia="true" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder">
                      <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>  </a>
                  </li>
                  <li class="instagram">
                    <a href="https://www.instagram.com/equitasitinc/" target="_blank" data-tippy="Instagram" data-tippy-inertia="true" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder">
                      <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg> </a>
                  </li>
                  <li class="linkedin">
                    <a href="https://www.linkedin.com/company/equitasitinc" target="_blank" data-tippy="Instagram" data-tippy-inertia="true" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder">
                      <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg> </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="copyrightsec">Copyright © 2024 EQUITAS IT INC All Rights Reserved. Designed & Developed by <a style={{color:'#fff'}} target="_blank" href='https://thedigitalally.com/'>Digital Ally</a> </div>
    </div>;
  }
}

export default Footer;
