import React from 'react';
import {
    Switch,
    Route,Redirect
} from "react-router-dom";
import Blogs from '../Blogs/Blogs';
import Home from '../Home/Home';
import Jobs from '../Jobs/Jobs';
import About from '../About/About';
import Contact from '../Contact/Contact';
import Services from '../Services/Services';
import Informationdetail from '../Services/Informationdetail/Informationdetail';
import Pharmaceuticalhelthcare from '../Services/Pharmaceuticalhelthcare/Pharmaceuticalhelthcare';
import Automotive from '../Services/Automotive/Automotive';
import PrivateEquity from '../Services/PrivateEquity/PrivateEquity';
import Engineering from '../Services/Engineering/Engineering';
import BankingFinancialServices from '../Services/BankingFinancialServices/BankingFinancialServices';
import Infrastructure from '../Services/Infrastructure/Infrastructure';
import InternetEcommerce from '../Services/InternetEcommerce/InternetEcommerce';
import Digital from '../Services/Digital/Digital';
import ResearchConsulting from '../Services/ResearchConsulting/ResearchConsulting';
import Jobdetails from '../Jobdetails/Jobdetails';
import Blogdetail from '../Blogs/Blogdetail/Blogdetail';
import Thankyou from '../Thankyou/ThankYou';
export default function Content(props) {
    return (
        <Switch>
            <Route exact path='/home' component={Home}></Route>
            <Route exact path='/about-us' component={About}></Route>
            <Route exact path='/services' component={Services}></Route>
            <Route exact path='/informationdetail' component={Informationdetail}></Route>
            <Route exact path='/Pharmaceuticalhelthcare' component={Pharmaceuticalhelthcare}></Route>
            <Route exact path='/automotive' component={Automotive}></Route>
            <Route exact path='/privateEquity' component={PrivateEquity}></Route>
            <Route exact path='/engineering' component={Engineering}></Route>
            <Route exact path='/bankingfinancialservices' component={BankingFinancialServices}></Route>
            <Route exact path='/infrastructure' component={Infrastructure}></Route>
            <Route exact path='/internetecommerce' component={InternetEcommerce}></Route>
            <Route exact path='/digital' component={Digital}></Route>
            <Route exact path='/researchconsulting' component={ResearchConsulting}></Route>
            <Route exact path='/contact-us' component={Contact}></Route>
            <Route exact path='/jobs' component={Jobs}></Route>
            <Route exact path='/blogs' component={Blogs}></Route>
            <Route exact path='/jobs/detail' component={Jobdetails}></Route>
            <Route exact path='/blog-detail' component={Blogdetail}></Route>
            <Route exact path='/Thankyou' component={Thankyou}></Route>
            <Redirect to='/home'/>
        </Switch>

    );
}