import React from 'react';
import { Nav } from 'react-bootstrap';

import {
    Link
  } from "react-router-dom";

const NavLink = (props) =>{

    var isActive = props.props.history.location.pathname.includes(props.to);
    var className = isActive ? 'nav-link active-menu' : 'nav-link';

    return(
        <Nav.Link as={Link} onSelect={(key, event) => alert(key)} to={props.to} onClick={()=> props.onClick()}  className={className} >{props.title}</Nav.Link>
    )
}

export default NavLink;