import React, { Component } from "react";
import jobsbanner from '../../../assets/images/jobsbanner.jpg';
import blogdetails from '../../../assets/images/blogdetails.jpg';
import blogdetails2 from '../../../assets/images/Infrastructures.jpg';
import { Container, Button, Row, Col } from 'react-bootstrap';
import * as blogs from '../../../Data/blogdetails';


class Infrastructure extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return <div className="Inner-page">
      <div className="inner-page-banner" style={{ backgroundImage: `url(${jobsbanner})` }}>

        <Container>
          <div className="banner-text">
            <div data-aos="fade-up"
              data-aos-duration="2000" >
              <h5>What we do</h5>
              <h1>Infrastructure</h1>
            </div>
          </div>
        </Container>

      </div>
      <div className="staffing-section p-80">
        <div className="container">
          <Row>
            <Col xs={12} md={12} lg={12} data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine">

              <img src={blogdetails2} alt="banner" />

            </Col>
            <Col xs={12} md={12} lg={12} data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine">

              <div className="staffingpstyle tickli">
                <div className="title-text afternone">

                  <h2>Infrastructure</h2>
                </div>


                <p>Infrastructure sector is the fundamental aspect of our economy and plays a crucial role in expedite the growth of our country’s overall development. The sector covers several sub-groups i.e. the market is segmented by social infrastructure, transportation infrastructure, manufacturing infrastructure, utilities and extraction infrastructure. </p>
                <p>We are determined to provide excellent talent solutions for major infrastructure projects and for infrastructure companies. With a strong presence as an employment agency catering to multiple sectors, we utilize our in-depth knowledge to provide you with outstanding recruitment services.</p>
                <ul>
                  <li>		We offer a broad set of staffing solutions that are designed to fit the current and evolving trends in the infrastructure sector.</li>
                  <li>	Our team of experts can source the qualified individuals that our clients require to meet their targets and organizational objectives.</li>
                  <li>		We have the abilities, networks and expertise to provide quality and experienced personnel across every discipline within the infrastructure sector.</li>
                  <li>	Our staffing professionals hire all levels of technical, managerial and supervisory personnel.</li>
                </ul>
              </div>
            </Col>
          </Row>

        </div>
      </div>
    </div>;
  }
}

export default Infrastructure;
