import React, { Component } from "react";
import jobsbanner from '../../../assets/images/jobsbanner.jpg';
import blogdetails from '../../../assets/images/blogdetails.jpg';
import blogdetails2 from '../../../assets/images/ecomors.jpg';
import { Container, Button, Row, Col } from 'react-bootstrap';
import * as blogs from '../../../Data/blogdetails';


class InternetEcommerce extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {

    return <div className="Inner-page">
      <div className="inner-page-banner" style={{ backgroundImage: `url(${jobsbanner})` }}>

        <Container>
          <div className="banner-text">
            <div data-aos="fade-up"
              data-aos-duration="2000" >
              <h5>What we do</h5>
              <h1>Internet/Ecommerce</h1>
            </div>
          </div>
        </Container>

      </div>
      <div className="staffing-section p-80">
        <div className="container">
          <Row>
            <Col xs={12} md={12} lg={12} data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine">

              <img src={blogdetails2} alt="banner" />

            </Col>
            <Col xs={12} md={12} lg={12} data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine">

              <div className="staffingpstyle tickli">
                <div className="title-text afternone">

                  <h2>Internet/Ecommerce</h2>
                </div>
                <p>The evolution of the internet has paved way for diverse opportunities for both businesses and individuals. The United States is known to be the second largest e-commerce market in the world. The e-commerce industry has witnessed a rapid increase in innovation across platforms.  </p>
                <p>With the upcoming e-commerce trends, the e-commerce industry requires more manpower. To address this gap, Equitas IT INC sources qualified candidates to make your organization’s operations easier and effective.</p>
                <ul>
                  <li>	We are an experienced e-commerce recruiter where our staffing experts truly know to identify suitable talent who can adapt to your online business.</li>
                  <li>	We understand the e-commerce trends, the way your business operates and we provide a personalized approach to deliver great results.</li>
                  <li>	We have a deep network and expertise that can help you scale your business as we recruit highly qualified individuals for your e-commerce team. </li>
                  <li>	Our specialized recruiting team hire candidates from entry-level to senior level.</li>
                </ul>
              </div>
            </Col>
          </Row>

        </div>
      </div>
    </div>;
  }
}

export default InternetEcommerce;
