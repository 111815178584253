import React, { Component } from "react";
import jobsbanner from '../../assets/images/jobsbanner.jpg';
import { Container, Row, Col } from 'react-bootstrap';
import aboutimg from '../../assets/images/about-img.jpg';
import 'react-tabs/style/react-tabs.css';

class About extends Component {
    render() {

        const contents = [
            {
                image: "images/line-3.png"
            }
        ]

        const contentss = [
            {
                image: "images/arrow-1.png"
            }
        ]
        const contentsss = [
            {
                image: "images/border-3.png"
            }
        ]
        return <div className="Inner-page">
            <div className="inner-page-banner" style={{ backgroundImage: `url(${jobsbanner})` }}>

                <Container>
                    <div className="banner-text">
                        <div data-aos="fade-up"
                            data-aos-duration="2000" >
                            <h5>What we do</h5>
                            <h1>About Us</h1>
                            <p>We are the industry leading IT staffing experts with a deep domain expertise.</p>
                        </div>
                    </div>

                </Container>

            </div>

            <div className="staffing-section p-80">
                <div className="container">
                    <Row>
                        <Col xs={12} md={6} data-aos="fade-right"
                            data-aos-duration="2000"
                            data-aos-easing="ease-in-sine">
                            <div className="title-text text-right">
                                <h5>WHO WE ARE</h5>
                                <h2>IT Staffing<br />
                                    Company</h2>
                            </div>
                            <img src={aboutimg} alt="banner" />
                        </Col>
                        <Col xs={12} md={6} data-aos="fade-left"
                            data-aos-duration="2000"
                            data-aos-easing="ease-in-sine">
                            <div className="maxpsize"><p><b>Equitas IT INC</b> combines deep technology, staffing knowledge & long-term experience to create talent solutions to increase teams’ efficiency & agility, resulting in faster time-to-market and operational cost savings.</p></div>
                            <div className="staffingpstyle tickli">
                                <h3>Staffing Services for IT Companies</h3>
                                <p>In the current scenario, businesses demand agility. We understand our clients’ requirements and individuals’ career aspirations. Equitas IT INC has gained a unique perspective of both business and talent. It is one of the rapidly growing IT staffing firms, delivering great results</p>

                                <ul>
                                    <li>We work actively with our clients and enable them to achieve & optimize the strategic components to business success – right skills, competencies, right people & right attitudes.</li>
                                    <li>We focus on fulfilling your needs; we support short-term, temporary or permanent IT staffing solutions.</li>
                                    <li>Our quality-oriented services help you in finding the top talent to meet your specific requirements.</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>

                </div>
            </div>



            <section class="process-style-two alternate-2">
                <div class="container">
                    <div class="inner-content">
                        {contents.map((item) =>
                            <div class="line" style={{ background: `url('${item.image}') no-repeat` }}></div>
                        )
                        }
                        <div class="row clearfix">
                            <div class="col-lg-6 col-md-6 col-sm-12 work-block">
                                <div class="work-block-two">
                                    <div class="inner-box">
                                        {contentss.map((item) =>
                                            <div class="arrow-box" style={{ background: `url('${item.image}') no-repeat` }}></div>
                                        )
                                        }
                                        <div class="icon-box">
                                            {contentsss.map((item) =>
                                                <div class="border-line" style={{ background: `url('${item.image}') no-repeat` }}></div>
                                            )
                                            }
                                            <svg viewBox="0 0 24 24" width="40" height="40" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="6"></circle><circle cx="12" cy="12" r="2"></circle></svg>
                                        </div>
                                        <div class="count-box wow zoomIn animated counted animated"><span><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="7 13 12 18 17 13"></polyline><polyline points="7 6 12 11 17 6"></polyline></svg></span></div>
                                        <div class="content-box wow slideInUp animated">
                                            <h2>Our Mission</h2>
                                            <p>We are committed to help businesses grow & thrive by connecting them to the right talent with extensive use of our resources. We aspire to be the leading partner for clients and candidates to connect with. We constantly aim to improve our services to our clients. We adapt to the industry trends as well as to our clients’ changing needs to ensure mutual success & growth.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 work-block">
                                <div class="work-block-two">
                                    <div class="inner-box">
                                        <div class="icon-box">
                                            {contentsss.map((item) =>
                                                <div class="border-line" style={{ background: `url('${item.image}') no-repeat` }}></div>
                                            )
                                            }
                                            <svg viewBox="0 0 24 24" width="40" height="40" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                                        </div>
                                        <div class="count-box wow zoomIn animated counted animated"><span><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="7 13 12 18 17 13"></polyline><polyline points="7 6 12 11 17 6"></polyline></svg></span></div>
                                        <div class="content-box wow slideInUp animated">
                                            <h2>Our Vision</h2>
                                            <p>Our vision is to emerge as a global leader in providing quality IT services to our clients by combining our domain expertise & technology skills and maintaining a long-term relationship with our clients. We are determined to connect great organizations with great people. We maximize the opportunities by consistently providing valuable HR solutions. We always strive to deliver what we promise.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>;
    }
}

export default About;
