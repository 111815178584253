import React, { Component } from "react";
import jobsbanner from '../../../assets/images/jobsbanner.jpg';
import blogdetails from '../../../assets/images/blogdetails.jpg';
import blogdetails2 from '../../../assets/images/engineerings.jpg';
import { Container, Button, Row, Col } from 'react-bootstrap';
import * as blogs from '../../../Data/blogdetails';


class Engineering extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {

    return <div className="Inner-page">
      <div className="inner-page-banner" style={{ backgroundImage: `url(${jobsbanner})` }}>

        <Container>
          <div className="banner-text">
            <div data-aos="fade-up"
              data-aos-duration="2000" >
              <h5>What we do</h5>
              <h1>Engineering </h1>
            </div>
          </div>
        </Container>

      </div>
      <div className="staffing-section p-80">
        <div className="container">
          <Row>
            <Col xs={12} md={12} lg={12} data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine">

              <img src={blogdetails2} alt="banner" />

            </Col>
            <Col xs={12} md={12} lg={12} data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine">

              <div className="staffingpstyle tickli">
                <div className="title-text afternone">

                  <h2>Engineering </h2>
                </div>


                <p>Engineering is one of the largest industrial sectors in the United States. Engineering industry comes with various sectors namely power, oil & gas, refinery, steel and consumer durables that have been driving the increasing demand in the industry. We help industries to thrive by connecting the relevant and qualified workforce for their operations.</p>
                <ul>
                  <li>	We cater our staffing services to various sectors and verticals across the engineering industry.</li>
                  <li>	We understand the unique challenges that arise when finding the technical talent for the industry.</li>
                  <li>		We bring years of experience and market knowledge to help our clients with their specific requirements.</li>
                  <li>	Our contract & direct hire staffing services enable you to eliminate costly hiring mistakes. We assess the candidates before making long-term hiring decisions.</li>
                </ul>
              </div>
            </Col>
          </Row>

        </div>
      </div>
    </div>;
  }
}

export default Engineering;
