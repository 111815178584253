import React, { Component } from "react";
import jobsbanner from '../../../assets/images/jobsbanner.jpg';
import blogdetails from '../../../assets/images/blogdetails.jpg';
import blogdetails2 from '../../../assets/images/privateequity.jpg';
import { Container, Button, Row, Col } from 'react-bootstrap';
import * as blogs from '../../../Data/blogdetails';


class PrivateEquity extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {


    return <div className="Inner-page">
      <div className="inner-page-banner" style={{ backgroundImage: `url(${jobsbanner})` }}>

        <Container>
          <div className="banner-text">
            <div data-aos="fade-up"
              data-aos-duration="2000" >
              <h5>What we do</h5>
              <h1>Private Equity </h1>
            </div>
          </div>
        </Container>

      </div>
      <div className="staffing-section p-80">
        <div className="container">
          <Row>
            <Col xs={12} md={12} lg={12} data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine">

              <img src={blogdetails2} alt="banner" />

            </Col>
            <Col xs={12} md={12} lg={12} data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine">

              <div className="staffingpstyle tickli">
                <div className="title-text afternone">

                  <h2>Private Equity </h2>
                </div>


                <p>Over the years, substantial growth in number of Private Equity firms has changed the competitive landscape in providing the right resources. The demand for right talent with good experience in investor backed businesses know how to transform businesses into highly accountable organizations. It is no longer about finding the candidates with relevant industry experience but find the right candidates that can match with the expectations of company’s stakeholders.</p>
                <ul>
                  <li>	We take pride in sourcing exceptional talent exclusively for private equity firms.</li>
                  <li>	We are committed to improve diversity promoting change by working with our clients.</li>
                  <li>	We have a proven experience in delivering highly qualified professionals to help our clients build their executive, middle and upper management teams.</li>
                  <li>	We also specialize in providing the appropriate talent on an interim and project basis.</li>
                </ul>
              </div>
            </Col>
          </Row>

        </div>
      </div>
    </div>;
  }
}

export default PrivateEquity;
