import React, { Component } from "react";
import jobsbanner from '../../../assets/images/jobsbanner.jpg';
import blogdetails from '../../../assets/images/blogdetails.jpg';
import blogdetails2 from '../../../assets/images/banks.jpg';
import { Container, Button, Row, Col } from 'react-bootstrap';
import * as blogs from '../../../Data/blogdetails';


class BankingFinancialServices extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {

    return <div className="Inner-page">
      <div className="inner-page-banner" style={{ backgroundImage: `url(${jobsbanner})` }}>

        <Container>
          <div className="banner-text">
            <div data-aos="fade-up"
              data-aos-duration="2000" >
              <h5>What we do</h5>
              <h1>Banking Financial Services </h1>
            </div>
          </div>
        </Container>

      </div>
      <div className="staffing-section p-80">
        <div className="container">
          <Row>
            <Col xs={12} md={12} lg={12} data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine">

              <img src={blogdetails2} alt="banner" />

            </Col>
            <Col xs={12} md={12} lg={12} data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine">

              <div className="staffingpstyle tickli">
                <div className="title-text afternone">

                  <h2>Banking Financial Services</h2>
                </div>


                <p>It is a known fact that Financial Markets in the United States are the largest and most liquid. The diversity of various sectors within the Banking & Financial Services industry supports the world’s largest economy to a great extent. </p>
                <p>EquitasITINC specializes in hiring qualified professionals for organizations across multiple disciplines of Banking & Financial Services. We are known to provide unparalleled service for our clients by finding the right people for the organizations.</p>
                <ul>
                  <li>	We solely focus on providing staffing solutions on a temporary, interim and full-time basis for banks, asset management, wealth management, fintech and other verticals.</li>
                  <li>	Our staffing specialists understand the financial market and come up with the best talent to suit your staffing needs.</li>
                  <li>	From entry-level to middle management and senior management, you can count on us. We deliver great staffing services with an ideal combination of temporary & full-time staff to keep your firm run smoothly.</li>
                  <li>	With deep domain expertise our team offers you a personalized service with a blend of local and industry insights.</li>
                </ul>
              </div>
            </Col>
          </Row>

        </div>
      </div>
    </div>;
  }
}

export default BankingFinancialServices;
