import React, { Component, useState } from "react";
import { Navbar, Container, Nav, NavDropdown, Form } from 'react-bootstrap';
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import equitlogo from '../../assets/images/equit-logo.png';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  withRouter
} from "react-router-dom";
import NavLink from "./NavLink";
import * as Data from '../../Data/headers.json';
import { useMediaQuery } from 'react-responsive';

const Header = (props) => {

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })

  const isBigScreen = useMediaQuery({ minWidth: 1824 })
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
  const isPortrait = useMediaQuery({ orientation: 'portrait' })
  const isRetina = useMediaQuery({ minResolution: '2dppx' })

  const [show, setShow] = useState(false);

  return <div className="main-header"><div className="top-header">
    <ul>
      <li><a href="mailto:admin@equitasitinc.com"> <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="4"></circle><path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path></svg>  admin@equitasitinc.com</a></li>
      <li><a href="#"> <FontAwesomeIcon icon={faMapMarkerAlt} /> 1630 W Prosper Trail suite 630, Prosper, TX 75078</a></li>
      <li><a href="tel:469-629-3066"> <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="16 2 16 8 22 8"></polyline><line x1="23" y1="1" x2="16" y2="8"></line><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg> (469) 629-3066</a></li>
    </ul></div>
    {
      isTabletOrMobile ? <Navbar collapseOnSelect={true} expanded={show} onToggle={() => setShow(!show)} bg="light" expand="lg">
        <Container>F
          <Navbar.Brand href="/home"> <img src={equitlogo} alt="Logo" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className={"justify-content-end"}>
            <Nav className="mr-auto">
              {
                Data.data.map((d, i) => {
                  return <NavLink onClick={() => setShow(!show)} to={d.to} title={d.title} key={'link-' + i} props={props} />
                })
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar> : <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="/home"> <img src={equitlogo} alt="Logo" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className={"justify-content-end"}>
            <Nav className="mr-auto">
              {
                Data.data.map((d, i) => {
                  return <NavLink onClick={() => setShow(!show)} to={d.to} title={d.title} key={'link-' + i} props={props} />
                })
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    }

  </div>
}

export default withRouter(Header);
