import React, { Component } from "react";
import jobsbanner from '../../../assets/images/jobsbanner.jpg';
import blogdetails from '../../../assets/images/blogdetails.jpg';
import blogdetails2 from '../../../assets/images/reserch.jpg';
import { Container, Button, Row, Col } from 'react-bootstrap';
import * as blogs from '../../../Data/blogdetails';


class ResearchConsulting extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return <div className="Inner-page">
      <div className="inner-page-banner" style={{ backgroundImage: `url(${jobsbanner})` }}>

        <Container>
          <div className="banner-text">
            <div data-aos="fade-up"
              data-aos-duration="2000" >
              <h5>What we do</h5>
              <h1>Research & Consulting</h1>
            </div>
          </div>
        </Container>

      </div>
      <div className="staffing-section p-80">
        <div className="container">
          <Row>
            <Col xs={12} md={12} lg={12} data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine">

              <img src={blogdetails2} alt="banner" />

            </Col>
            <Col xs={12} md={12} lg={12} data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine">

              <div className="staffingpstyle tickli">
                <div className="title-text afternone">

                  <h2>Research & Consulting </h2>
                </div>


                <p>Research & Consulting focuses on specialized and targeted research to analyse and produce the key findings. These opportunities extend to multiple fields and areas allowing a huge scope of employment to the individuals. This industry demands in-depth expertise, making critical and strategical decisions by providing unmatched insights.</p>
                <p>The rise of research and consulting firms have made it possible to deliver accurate information for extraordinary results. With a wide variety of career options in the research industry, Equitas IT INC takes pride to associate with global leading companies and provides them with an unparalleled experience.</p>
                <ul>
                  <li>	We help our clients with our quick services; we hire the exact relevant talent they need to build better organizations.</li>
                  <li>	We provide research & consulting recruitment for corporate talent acquisition teams who are in need of timely and precise information.</li>
                  <li>	Our team of professionals have deep expertise in the domain and therefore carefully pay heed to your staffing needs.</li>
                  <li>	Our tailored staffing solutions are cost-effective that are designed to meet your goals.</li>
                </ul>
              </div>
            </Col>
          </Row>

        </div>
      </div>
    </div>;
  }
}

export default ResearchConsulting;
