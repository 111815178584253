import React, { Component } from "react";
import jobsbanner from '../../../assets/images/jobsbanner.jpg';
import blogdetails from '../../../assets/images/blogdetails.jpg';
import blogdetails2 from '../../../assets/images/informationtechnology.jpg';
import { Container, Button, Row, Col } from 'react-bootstrap';
import * as blogs from '../../../Data/blogdetails';


class Informationdetail extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {

    return <div className="Inner-page">
      <div className="inner-page-banner" style={{ backgroundImage: `url(${jobsbanner})` }}>

        <Container>
          <div className="banner-text">
            <div data-aos="fade-up"
              data-aos-duration="2000" >
              <h5>What we do</h5>
              <h1>Information Technology</h1>
            </div>
          </div>
        </Container>

      </div>
      <div className="staffing-section p-80">
        <div className="container">
          <Row>
            <Col xs={12} md={12} lg={12} data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine">

              <img src={blogdetails2} alt="banner" />

            </Col>
            <Col xs={12} md={12} lg={12} data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-easing="ease-in-sine">

              <div className="staffingpstyle tickli">
                <div className="title-text afternone">

                  <h2>Information Technology</h2>
                </div>


                <p>Information Technology is a dynamic industry that plays an important role in the contribution of burgeoning digital economy. The United States is known to be the largest tech market in the world that provides numerous employment opportunities to aspiring candidates.</p>
                <p>Our well-experienced recruiters who come from IT industry have a deep understanding & exhibit domain expertise in helping and managing the clients. They play a crucial role in acquiring the right talent and they adapt to the timely changes happening in the industry to take advantage of the new opportunities.</p>
                <p>Equitas IT INC delivers innovative & customized solutions to clients enabling them to adapt to the latest technologies to strengthen their company’s future.</p>
                <ul>
                  <li>	Level up your business operations with our innovative IT Solutions & Services. </li>
                  <li>	From engaging clients and implementing technology to delivering service, our IT Solutions & Services give access to our proficiency in advanced technologies.</li>
                  <li>	We build and support IT infrastructure solutions along with security and application services by making use of traditional and cloud technologies.</li>
                  <li>	Our IT infrastructure service is backed by a collective business, technical and industry expertise.</li>
                </ul>
              </div>
            </Col>
          </Row>

        </div>
      </div>
    </div>;
  }
}

export default Informationdetail;
